import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemText, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import axios, { CancelTokenSource } from 'axios';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from "sweetalert2";

interface IFormInput {
    file: { file: File | null, name: string };
    id: number
}

interface UploadStatus {
    status: 'idle' | 'uploading' | 'success' | 'error';
    errorMessage?: string;
}

// Custom Backdrop component
const CustomBackdrop = (props: any) => {
    return <Backdrop {...props} onClick={(e) => e.stopPropagation()} />;
};

const ReuploadDocument: React.FC<{ onOpen: any; onClose: any; page: any; rowsPerPage:any;
    dateRange:any; companyNumber:any; getAllExternalUsersDocument: any; fileId:any }> = (props) => {

    const { onOpen, onClose, page, rowsPerPage, dateRange, companyNumber, getAllExternalUsersDocument, fileId } = props;

    const { control, handleSubmit, setValue, watch, formState: { errors }, reset, register } = useForm<IFormInput>({
        defaultValues: {
            file: {file: null, name: ''},
            id: 0
        },
    });

    const selectedFile = watch('file');
    const [uploadStart, setUploadStart] = useState(false);
    const [editingFileName, setEditingFileName] = useState<string | null | undefined>(null);
    const [uploadStatus, setUploadStatus] = useState<UploadStatus>({ status: 'idle' });
    const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource | null>(null);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUploadStatus({ status: 'idle' });
        const file = e.target.files?.[0] as File;
        setValue('file', { file, name: file?.name || '' }, { shouldValidate: true });
    };

    const onSubmit: SubmitHandler<IFormInput> = async (data) => {

        if (!data?.file?.file) {
            return;
        }

        setUploadStart(true)
        const token = localStorage.getItem("token");
        const cancelToken = axios.CancelToken;
        const source = cancelToken.source();
        setCancelTokenSource(source);
        const formData = new FormData();
        formData.append('reuploaded_file', data?.file?.file, data?.file?.name);
        formData.append('original_file_id', String(onOpen?.id));

        setUploadStatus({ status: 'uploading' });

        await axios.post('https://ntca-42467-staging.botics.co/document/reupload/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: token ? "Token " + token : null
            },
            cancelToken: source.token,
        })
            .then(res => {
                setUploadStatus({ status: 'success' });
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    setUploadStatus({ status: 'idle' });
                    return;
                }
                setUploadStatus({ status: 'error', errorMessage: error.response?.data?.error || 'Upload failed' });
            });

        setUploadStart(false);
    }

    const cancelUpload = () => {
        if (cancelTokenSource) {
            cancelTokenSource.cancel('File upload cancelled by user');
            setCancelTokenSource(null);
            setUploadStart(false);
            //closeDialog()
        }
    };

    const handleFileNameChange = (newName: string) => {
        selectedFile.name = newName;
        setValue('file', selectedFile);
      };

    const closeDialog = () => {
        if (fileId) {
            getAllExternalUsersDocument(1, rowsPerPage, fileId, { from: undefined, to: undefined }, 'all');
        } else {
            getAllExternalUsersDocument(page, rowsPerPage, fileId, dateRange, companyNumber);
        }
        // getAllExternalUsersDocument(page, rowsPerPage, dateRange, companyNumber);
        reset();
        onClose();
    }

    useEffect(() => {
        if (!uploadStart && selectedFile?.file && uploadStatus?.status === 'success') {
            closeDialog();
            Swal.fire({
                text: "Documents Successfully Re-Uploaded.",
                icon: "success",
                customClass: {
                    confirmButton: 'custom-confirm-button',
                    cancelButton: 'custom-cancel-button',
                    htmlContainer : 'custom-html-container',
                   }
            }).then((result) => {
                if (result.isConfirmed) {
                    if (fileId) {
                        console.log(fileId);
                        getAllExternalUsersDocument(1, rowsPerPage, fileId, { from: undefined, to: undefined }, 'all');
                    } else {
                        getAllExternalUsersDocument(page, rowsPerPage, fileId, dateRange, companyNumber);
                    }
                    // getAllExternalUsersDocument(page, rowsPerPage, dateRange, companyNumber);
                }
            })
                .catch(error => {
                    closeDialog();
                })
        }
    }, [uploadStatus, uploadStart]);

    useEffect(() => {
        return () => {
            if (cancelTokenSource) {
                cancelTokenSource.cancel('File upload cancelled by user');
            }
        };
    }, [cancelTokenSource]);

    return (
        <>
            <Dialog
                open={onOpen?.open}
                onClose={onClose}
                fullWidth={true}
                slots={{ backdrop: CustomBackdrop }}
                disableEscapeKeyDown
                maxWidth='sm'
                scroll={'body'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{
                    component: 'form',
                    onSubmit: handleSubmit(onSubmit),
                    error: errors,
                }}
            >
                <DialogTitle sx={{ fontWeight: 'bold' }}>Re-Upload Document</DialogTitle>
                <DialogContent sx={{ height: '300px' }}>
                    <Stack
                        mt={2}
                        justifyContent='center'
                        alignItems='center'
                        sx={{
                            border: '1px dashed #e0e0e0',
                            width: '100%',
                            padding: '25px',
                            borderRadius: '5px'
                        }}
                    >
                        <input
                            type="file"
                            id="file-upload"
                            {...register('file', {
                                validate: (file: string | any) => file?.file!==null || 'You must select a file'
                            })}
                            disabled={uploadStart}
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                            accept='.doc, .docm, .docx, .dot, .dotm, .dotx, .htm, .html, .msg, .pdf, .rtf, .txt, .wpd, .xps, .jpg, .csv, .xls, .xlsm, .xlsx'
                        />
                        <label htmlFor="file-upload">
                            <Typography><UploadFileIcon sx={{ color: '#0070B4', marginLeft: '60px' }} /></Typography>
                            <Button variant="text" component="span" sx={{ '&:hover': { textDecoration: 'underline' } }}
                                disabled={uploadStart}
                            >
                                Click to upload
                            </Button>
                        </label>
                        <Typography sx={{ fontSize: '14px', color: '#666666', textAlign: 'center' }}>
                            .doc, .docm, .docx, .dot, .dotm, .dotx, .htm, .html, .msg, .pdf, .rtf, .txt, .wpd, .xps jpg Spreadsheet-.csv, .xls, .xlsm, .xlsx
                        </Typography>
                        {errors.file && (
                            <Typography color="error" variant="body2">
                                {errors.file.message}
                            </Typography>
                        )}
                    </Stack>
                    
                    {selectedFile.file && (

                        <List sx={{ marginTop: '16px', width: '100%', maxWidth: '100%', border: '1.7px dashed rgba(123, 97, 255, .5)', borderRadius: '5px' }}>
                            <ListItem
                                secondaryAction={
                                    <IconButton edge="end" aria-label="delete">
                                        {
                                            uploadStatus.status === 'uploading' ? <CircularProgress size={24} /> :
                                                uploadStatus.status === 'success' ? <CheckCircleIcon color="success" /> :
                                                    uploadStatus.status === 'error' ? <ErrorIcon color="error" /> :
                                                        <DeleteIcon onClick={() => setValue('file', { file: null, name: '' }, { shouldValidate: true })} />
                                        }
                                    </IconButton>
                                }
                            >

                                {editingFileName === selectedFile?.file.name && uploadStatus.status !== 'uploading' && uploadStatus.status !== 'success' && uploadStatus.status !== 'error' ? (
                                    <TextField
                                        value={selectedFile.name}
                                        // onChange={(e) => setValue('file', { file: selectedFile.file, name: e.target.value }, { shouldValidate: true })}
                                        onChange={(e) => handleFileNameChange(e.target.value)}
                                        onBlur={() => setEditingFileName(null)}
                                        autoFocus
                                    />
                                ) : (
                                    <>
                                        <UploadFileIcon sx={{ color: uploadStatus.status === 'error' ? 'red' : '#0070B4', marginRight: '15px' }} />

                                        <ListItemText sx={{
                                            cursor: 'pointer',
                                            color: uploadStatus.status === 'error' ? 'red' : ''
                                        }}
                                            primary={selectedFile?.name}
                                            secondary={`${Number(selectedFile.file?.size / 1024).toFixed(1)}kb`}
                                            primaryTypographyProps={{ fontSize: '12px', onClick: () => setEditingFileName(selectedFile?.file?.name) }}
                                            secondaryTypographyProps={{ fontSize: '11px' }}
                                        />
                                        {uploadStatus.status === 'error' && (
                                            <Typography color="error" variant="body2">
                                                {uploadStatus.errorMessage}
                                            </Typography>
                                        )}
                                    </>
                                )}

                            </ListItem>
                        </List>

                    )}
                </DialogContent>
                <DialogActions>
                    {uploadStart ?
                        <Button onClick={cancelUpload} sx={{ color: '#0073AE', '&:hover': { color: '#FDB913' } }}>Cancel Upload</Button>
                        :
                        <Button onClick={closeDialog} sx={{color: '#0073AE', '&:hover': { color: '#FDB913' } }}>Close</Button>
                    }
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{bgcolor: '#0073AE', '&:hover': { bgcolor: '#FDB913', color: '#000000' } }}
                        disabled={uploadStart}
                    >
                        {uploadStart ? <CircularProgress size={24} /> : 'Done'}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default ReuploadDocument;